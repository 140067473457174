<template>
	<div class="h-screen flex flex-col items-center justify-center">
		<div class="illustration">
			<img
				src="@/assets/images/illustrations/sent.png"
				height="150"
				width="150"
				alt=""
			/>
		</div>
		<div class="title text-neutral-700 mt-8 mb-4">Oops!!</div>
		<p class="mb-8 text-neutral-500">Không tìm thấy trang.</p>
		<router-link to="/">
			<button type="button" class="w-52 button-p btn--lg btn--ghost">
				Đến Trang Chủ
			</button>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
};
</script>
<style lang="scss" scoped>
.title {
	font-weight: bold;
	font-size: 33px;
	line-height: 40px;
	text-align: center;
	letter-spacing: -0.02em;
}

p {
	max-width: 450px;
	font-size: 18px;
	line-height: 24px;
}
</style>

<style scoped></style>
